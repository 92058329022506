<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-date-picker v-model="params.month" type="month" value-format="yyyy-MM" placeholder="选择创建月份"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="请选择员工" filterable  multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getCheckin({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="$refs['config'].open()">考勤规则</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="checking_in_table" class="checking_in scroll-wrapper absolute" height="100%" :data="checkings" :size="theme.size" :row-class-name="tableRowClassName">
				<el-table-column label="员工" min-width="98">
					<template slot-scope="scope">
						<img style="display: block;" :src="scope.row.signatured" v-if="scope.row.signatured">
						<el-link type="primary" :disabled="!$utils.update($api.URI_CHECKIN)" @click="checking = {...scope.row}; checkings_dialog = true;" v-else>{{scope.row.employee.name}}</el-link>
					</template>
				</el-table-column>
				<el-table-column align="center" label="出勤天数">
					<template slot="header">出勤天数 <el-tooltip style="cursor: help;" effect="dark" content="标准为当前企业最大实际出勤天数，实际出勤天数 = 正常打卡 + 异常打卡 - 缺卡"><i class="el-icon-info"></i></el-tooltip></template>
					<el-table-column label="标准" prop="standard_days" min-width="46"></el-table-column>
					<el-table-column label="实际" prop="actual_days" min-width="46"></el-table-column>
				</el-table-column>
				<el-table-column align="center" label="打卡天数">
					<template slot="header">打卡天数 <el-tooltip style="cursor: help;" effect="dark" content="标准含休息日(即当月自然日天数)，正常含出差/假期，异常含迟到/早退/旷工/缺卡累计天数(非次数)"><i class="el-icon-info"></i></el-tooltip></template>
					<el-table-column label="标准" prop="work_days" min-width="46"></el-table-column>
					<el-table-column label="正常" prop="regular_days" min-width="46"></el-table-column>
					<el-table-column label="异常" prop="except_days" min-width="46"></el-table-column>
					<el-table-column label="缺卡" prop="exception_count_3" min-width="46"></el-table-column>
				</el-table-column>
				<el-table-column align="center" v-for="(exception, e) in exceptions" :key="'exception_'+e" :label="exception">
					<el-table-column label="次数" min-width="46" :formatter="(r) => { return (r['exception_count_'+e] || 0); }"></el-table-column>
					<el-table-column label="分钟" min-width="46" :formatter="(r) => { return (r['exception_duration_'+e] || 0)/60; }"></el-table-column>
				</el-table-column>
				<el-table-column align="center" label="出差">
					<el-table-column label="次数" min-width="46" prop="sp_count_3"></el-table-column>
					<el-table-column label="小时" min-width="58" :formatter="({sp_duration_3}) => { return +(sp_duration_3).toFixed(2); }"></el-table-column>
				</el-table-column>
				<el-table-column align="center" label="假勤">
					<el-table-column :min-width="vacation.time_attr ? 86 : 76" v-for="vacation in vacations" :key="'vacation_'+vacation.id" :label="vacation.name" :formatter="(r) => { return (r['vacation_duration_'+vacation.id] || 0) + (vacation.time_attr ? ' 小时' : ' 天'); }"></el-table-column>
				</el-table-column>
				<el-table-column label="更新时间" prop="updated_at" min-width="152">
					<template slot="header">更新时间 <el-tooltip style="cursor: help;" effect="dark" content="更新来源于创建后次月1~5号自动获取OA上月最新考勤数据。当企业允许次月处理上月打卡异常有效，OA 无法获取更新已离职人员打卡记录，请人事在离职人员次月确认考勤后再在 OA 移除"><i class="el-icon-info"></i></el-tooltip></template>
				</el-table-column>
				<el-table-column label="创建时间" prop="created_at" min-width="152">
					<template slot="header">创建时间 <el-tooltip style="cursor: help;" effect="dark" content="创建来源于每月最后一天自动获取OA当月考勤数据"><i class="el-icon-info"></i></el-tooltip></template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getCheckin({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getCheckin({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<rules ref="config"></rules>
		<el-dialog :title="checking.id ? '编辑出勤' : '添加出勤'" width="320px" :visible.sync="checkings_dialog" append-to-body destroy-on-close>
			<el-form label-width="80px" ref="checking_form" :model="checking" :rules="checking_rules" :size="theme.size" status-icon>
				<el-form-item label="员工" v-if="checking.employee">{{checking.employee.name}}</el-form-item>
				<el-form-item label="标准" prop="standard_days">
					<el-input-number placeholder="标准" v-model="checking.standard_days" :min="1" :max="31"></el-input-number> 天
				</el-form-item>
				<el-form-item label="实际" prop="actual_days">
					<el-input-number placeholder="实际" v-model="checking.actual_days" :min="1" :max="31"></el-input-number> 天
				</el-form-item>
				<el-form-item>
					<p style="color: #999; font-size: 12px; line-height: 1.5;">Tips：只可手动更新出勤天数，其它值只能使用脚本自动更新同步 OA</p>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('checking_form')">{{checking.id ? '提交保存'  : '提交创建'}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<style>
	.el-table.checking_in .warning-row {
		background: oldlace;
	}

	.el-table.checking_in .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	import rules from './rules';

	export default {
		components: {
			mainTable,
			rules
		},
		computed: {
			...mapState(['theme', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			}
		},
		methods: {
			tableRowClassName({row}) {
				if (row.signatured) return 'success-row';
				return '';
			},
			async submitCheckin (data) {
				const res = await this.$http.post(this.$api.URI_CHECKIN, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getCheckin(this.params);
						this.checkings_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'checking_form':
							this.submitCheckin(this.checking);
						break;
					}
				});
			},
			async getCheckin (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CHECKIN, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.checkings = result.data;
				this.employees = result.employees;
				this.vacations = result.vacations;
				this.exceptions = result.exceptions;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				checking: {},
				checkings: [],
				vacations: [],
				employees: [],
				exceptions: [],
				checking_rules: {
					actual_days: [{ required: true, message: '请输入实际出勤天数', trigger: 'blur' }],
					standard_days: [{ required: true, message: '请输入应该出勤天数', trigger: 'blur' }]
				},
				checkings_dialog: false,
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getCheckin(this.params, true);
		}
	};
</script>