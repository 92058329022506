<template>
	<el-dialog width="680px" title="考勤规则" :visible.sync="config_dialog" append-to-body destroy-on-close>
		<el-tabs style="margin-top: -30px;" v-model="active_name" v-if="config_dialog">
			<el-tab-pane label="异常规则" name="unusual" lazy>
				<unusual v-if="active_name === 'unusual'"></unusual>
			</el-tab-pane>
			<el-tab-pane label="假期规则" name="holiday" lazy>
				<holiday v-if="active_name === 'holiday'"></holiday>
			</el-tab-pane>
		</el-tabs>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import unusual from './unusual';
	import holiday from './holiday';

	export default {
		components: {
			unusual,
			holiday
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			open () {
				this.config_dialog = true;
			}
		},
		data() {
			return {
				active_name: 'unusual',
				config_dialog: false,
			}
		}
	};
</script>